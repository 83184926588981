<template>
  <div class="container">
    <SimpleHeader :name="title" />

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <div class="teamw">
        <div class="searchBlock">
          <div class="sift-box">
            <div class="sift-box--time">
              <div class="sift-item pr-20px font-w flex--c arrow" @click="timePickerShow">
                <div>{{ time.timeTitle }}</div>
              </div>
              <div class="sift-item fcontentCenter" @click="timeChangeShow('startDate')">
                <div :class="{ hasValue: time.startDate != '' }">{{ time.startDate || '开始时间' }}</div>
              </div>
              <div class="sift-item fcontentCenter" @click="timeChangeShow('endDate')">
                <div :class="{ hasValue: time.endDate != '' }">{{ time.endDate || '结束时间' }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="team">
          <div class="team-it left">
            <div class="team-it-label">{{ self.name }}</div>
            <div class="team-it-num">{{ self.value }}</div>
            <div class="team-it-txt ">
              <span class="">较前一日 </span>
              <span class="count" :class="self.ratio > 0 ? 'up' : self.ratio < 0?'down':''">{{ self.ratio }}%</span>
            </div>
          </div>
          <div class="team-it right">
            <div class="team-it-label">{{ team.name }}</div>
            <div class="team-it-num">{{ team.value }}</div>
            <div class="team-it-txt ">
              <span class="">较前一日 </span>
              <span class="count" :class="team.ratio > 0 ? 'up' : team.ratio < 0?'down':''">{{ team.ratio }}%</span>
            </div>
          </div>
        </div>
        <div class="info">
          <div class="info-it" v-for="(item,index) in list" :key="index">
            <div class="info-it-num">{{ item.value }}</div>
            <div class="info-it-txt">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </van-pull-refresh>
    <div class="foot-btns">
      <div class="foot-btnbox">
        <button class="foot-btn"  @click="toDetail">{{btnName}}</button>
      </div>
    </div>
    <div class="popBox" v-show="time.timePicker">
          <van-picker
            class="popBottom"
            title="选择时间"
            show-toolbar
            value-key="title"
            :columns="timeTypeList"
            @confirm="onConfirm"
            @cancel="onCancel"
          />
        </div>
        <div class="popBox" v-show="time.timeWhen">
          <van-datetime-picker
            v-model="show_time"
            class="popBottom"
            type="date"
            title="选择时间"
            @confirm="onConfirmTime"
            @cancel="onCancelTime"
          />
        </div>
  </div>
</template>
<script>
import SimpleHeader from '@/components/SimpleHeader'
import { reactive, onMounted, toRefs, nextTick, inject, watch } from 'vue'
import { useRoute,onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router'
import { PullRefresh,Toast } from 'vant'
import timeTypeList from '@/utils/timeType'
import utils from '@/utils'
import { teamOverdiv,getTeamInfo } from '@/api/team'
import usePage from '@/hooks/usePage'
import useTime from '@/utils/time'
import {setLocal, getLocal } from '@/common/js/utils'
import to from 'await-to-js';

export default {
  name: 'team',
  components: {
    [PullRefresh.name]: PullRefresh,
    SimpleHeader
  },
  setup() {
    const _appLoadFanish = inject('appLoadFanish');
    const {
      time,
      show_time,
      onConfirmTime,
      onCancelTime,
      onConfirm,
      onCancel,
      timePickerShow,
      timeChangeShow
    } = useTime()
    const { query } = useRoute()
    const { navigateTo } = usePage()
    const state = reactive({
      userId:'',
      refreshing: false,
      loading: false,
      timeTypeList,
      limitDate: utils.computedTime('today').endDate,
      time_index: 0, // 默认全部时间
      list: [],
      self: {},
      team: {},
      btnName: '查看团队明细',
      title: ''
    })
    // 时间变化重新请求数据
    watch(
      () => [time.startDate, time.endDate],
      () => {
        state.page = 1
        getData()
      }
    )
    const initData = () => {
      state.self = {}
      state.team = {}
      state.list = []
    }
    const getData = async ()=> {
      if (state.refreshing) {
        initData()
        state.refreshing = false
      }
      let params = {
        start: time.startDate,
        end: time.endDate,
        page:state.page,
        user_id:state.userId
      }
      let [error,result] = await to(getTeamInfo(params))
      _appLoadFanish()
      state.loading = false
      if(error) return;
      let {code, res} = result;
      if (code === 0) {
        state.self = res.self
        state.team = res.team
        state.list = res.list
        state.btnName = res.btnName
        state.title = res.title
      }
    }
    //刷新页面
    const onRefresh = () => {
      state.loading = true
      getData()
    }

    const toDetail = url => {
      navigateTo(`/teamDetail?id=${state.userId}`)
    }

    const setSnapshot = ()=> {
      let teamParams = JSON.parse(getLocal('teamParams')||'[]')
      teamParams.push({
        userId: state.userId,
        start: time.startDate,
        end: time.endDate
      })
      setLocal('teamParams',JSON.stringify(teamParams))
    }
    const getSnapshot = ()=> {
      return JSON.parse(getLocal('teamParams')||'[]')
    }
    const removeSnapshot = ()=> {
      return setLocal('teamParams',JSON.stringify([]))
    }
    // 路由离开
    onBeforeRouteLeave((to, from) => {
      if (to.path == '/teamDetail'){
        setSnapshot()
      }
    })
    const initTime = (type) => {
      let tempTime = state.timeTypeList.find(v=>v.value == type)
      if (tempTime) {
        onConfirm(tempTime)
      }
    }
    onMounted(() => {
      initTime('curWeek')
      if (query && query.hasOwnProperty('id')) {
        state.userId = query.id
      }
    })

    return {
      ...toRefs(state),
      onRefresh,
      onConfirm,
      onCancel,
      timePickerShow,
      timeChangeShow,
      time,
      show_time,
      onConfirmTime,
      onCancelTime,
      toDetail,
      getSnapshot,
      removeSnapshot
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../common/style/searchpicker';
.container {
  background: #f8f8f8;
  min-height: 100%;
  padding: 0 15px;
  padding-bottom: 50px;
  .teamw{
    padding-bottom: 50px;
    padding-bottom: calc(50px + constant(safe-area-inset-bottom,50px))!important;
    padding-bottom: calc(50px + env(safe-area-inset-bottom,50px))!important;
  }
}
.van-pull-refresh{
  min-height: 100%;
}
.searchBlock {
  margin: 10px 0;
  height: 36px;
  display: flex;
  align-items: center;
}
.team{
  background: #fff;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 30px 0;
  &-it{
    flex: 1;
    &-title{
      line-height: 1.5;
    }
    &-num{
      line-height: 1.8;
      font-size: 20px;
      font-weight: bold;
      columns: #333;
    }
    .up{
      color: red;
    }
    .down{
      color: #26BA00;
    }
    &-txt{
      .count{
        padding-right: 20px;
        &.up{
          background: url('https://x.jzbull.com/dsx-h5/up.png') no-repeat;
          background-position: right center;
          background-size: 18px;

        }
        &.down{
          background: url('https://x.jzbull.com/dsx-h5/down.png') no-repeat;
          background-position: right center;
          background-size: 18px;
        }
      }
    }
  }
}
.info{
    margin-top: 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    background: #fff;
    box-sizing: border-box;
    // padding: 0 20px;
    &-it{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 80px;
      flex: 0 0 33.3%;
      box-sizing: border-box;
      &-num{
        margin-bottom: 4px;
        font-size: 15px;
      }
      &-txt{
        color: #999;
        font-size: 14px;
      }
    }
}


</style>
